import { useRef } from "react";
import { linksArray } from "../../../_mocks_/data";
import NavbarItem from "./NavbarItem/NavbarItem";
const Navbar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  return (
    <>
      <nav ref={navRef}>
        <ul>
          {linksArray.map((link) => (
            <NavbarItem
              key={link.label}
              item={link}
              showNavbar={() => showNavbar()}
            />
          ))}
          <button className="nav__btn nav__btn--close" onClick={showNavbar}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </ul>
      </nav>
      <div className="nav__btn--open">
        <button className="nav__btn" onClick={showNavbar}>
          <i className="fa-solid fa-bars-staggered"></i>
        </button>
      </div>
    </>
  );
};

export default Navbar;
