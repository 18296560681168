import Hero from "../../components/Hero/Hero";
import styles from "./Welcome.module.scss";
const Welcome = () => {
  return (
    <div id="welcome" className={styles.welcome}>
      <Hero />
    </div>
  );
};

export default Welcome;
