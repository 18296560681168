import styles from "./Social.module.scss";

/**
 *
 * @returns {HTMLElement}
 */
const Social = () => {
  return (
    <figure className={styles.social}>
      <a
        rel="noreferrer"
        target="_blank"
        title="Github"
        href="https://github.com/Stephan075"
      >
        <i className="fab fa-github" aria-hidden="true"></i>
      </a>

      <a
        rel="noreferrer"
        target="_blank"
        title="Linkedin"
        href="https://www.linkedin.com/in/stephan-folio/"
      >
        <i className="fa-brands fa-linkedin" aria-hidden="true"></i>
      </a>
    </figure>
  );
};

export default Social;
