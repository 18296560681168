import React from "react";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const NavbarItem = ({ item, showNavbar }) => {
  let location = useLocation();

  return (
    <li onClick={showNavbar}>
      <Link
        className={`${location.hash === item.to ? " active" : ""}`}
        to={item.to}
        smooth
      >
        {item.label}
      </Link>
    </li>
  );
};

export default NavbarItem;
