import React from "react";
import { useState } from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import styles from "./Contact.module.scss";

/**
 *
 * @returns {HTMLElement}
 */
const Contact = () => {
  const [isSend, setIsSend] = useState(false);
  const [name, setName] = useState("");
  return (
    <section id="contact">
      <div className={`${styles.contact}`}>
        <div className="container">
          <h1 className="title text-uppercase">ME CONTACTER</h1>
          {!isSend ? (
            <>
              <p className="title-sub">
                C'est avec plaisir que j'échangerai avec vous pour discuter plus
                en détails de mon parcours, de mes compétences et de ma
                motivation. N'hésitez pas à me contacter via mon profil LinkedIn
                ou encore grâce au formulaire de contact ci-dessous.
              </p>
              <ContactForm
                setIsSend={() => setIsSend(true)}
                setName={setName}
              />
            </>
          ) : (
            <div className="text-center">
              <p>
                Merci {name}! Votre email a bien été envoyé. Je vous répondrai
                dans les plus brefs délais ! 😊
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
