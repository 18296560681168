import React from "react";
import { skillsList } from "../../_mocks_/data";
import styles from "./Skills.module.scss";

/**
 *
 * @returns {HTMLElement}
 */
const Skills = () => {
  return (
    <section id="skills">
      <div className="container">
        <h1 className="title text-uppercase">MES COMPÉTENCES</h1>

        <div className={`${styles.skills}`}>
          <ul>
            {skillsList.map((skill) => (
              <li key={skill.label}>
                <img src={skill.img} alt="" />
                <div className="skills-elem-text">
                  <p>{skill.label}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Skills;
