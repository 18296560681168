import styles from "./ContactForm.module.scss";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import PropTypes from "prop-types";

/**
 * @param {function} setIsSend -  modifier le state à true si le mail est bien envoyé
 * @param {boolean} setName -  récupérer le nom de la personne qui envoie l'email et le modifier dans le state
 * @returns {HTMLElement}
 */
const ContactForm = ({ setIsSend, setName }) => {
  const form = useRef();
  const defaultValues = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };

  const contactSchema = yup.object({
    name: yup
      .string()
      .required("Merci de rentrer votre nom et prénom")
      .max(30, "le champ doit comporter moins de 30 caractères"),
    email: yup
      .string()
      .email("Merci de renter une adresse mail valide")
      .max(255)
      .required("Merci de rentrer une adresse mail"),
    phone: yup
      .number()
      .typeError("Merci de rentrer une numero de téléphone valide")
      .required("Merci de rentrer un numero de téléphone"),
    subject: yup
      .string()
      .required("De quoi voulez-vous discuter ?")
      .max(20, "Le champ doit comporter moins de 20 caractères"),
    message: yup
      .string()
      .min(10, "Le champ doit contenir au moins 10 caractères")
      .required("Merci de rentrer un message"),
    persoData: yup
      .boolean()
      .oneOf([true], "Veuillez cocher cette case si vous souhaitez continuer."),
  });

  const {
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm({
    defaultValues,
    resolver: yupResolver(contactSchema),
  });

  async function submit(values) {
    try {
      clearErrors();

      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            console.log(result.text);
            setIsSend();
            setName(values.name);
            reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    } catch (e) {
      console.log({ message: "Il y a eu une erreur" });
    }
  }

  return (
    <form
      ref={form}
      onSubmit={handleSubmit(submit)}
      className={`d-flex flex-column card p-20 ${styles.contactForm}`}
    >
      {/* Name */}
      {errors.name && <p className="form-error">{errors.name.message}</p>}
      <div className="d-flex flex-column mb-20">
        <label htmlFor="name">Nom et prénom * :</label>
        <input
          {...register("name")}
          type="text"
          placeholder="Votre nom & prénom *"
        />
      </div>
      {/* Email */}
      {errors.email && <p className="form-error">{errors.email.message}</p>}
      <div className="d-flex flex-column mb-20">
        <label htmlFor="email">Email * :</label>
        <input
          {...register("email")}
          type="email"
          placeholder="Votre email *"
        />
      </div>
      {/* Phone */}
      {errors.phone && <p className="form-error">{errors.phone.message}</p>}
      <div className="d-flex flex-column mb-20">
        <label htmlFor="phone">N° de Téléphone :</label>
        <input
          {...register("phone")}
          type="text"
          placeholder="Votre numéro de téléphone"
        />
      </div>
      {/* Subject */}
      {errors.subject && <p className="form-error">{errors.subject.message}</p>}
      <div className="d-flex flex-column mb-20">
        <label htmlFor="subject">Sujet * :</label>
        <input {...register("subject")} type="subject" placeholder="Sujet *" />
      </div>
      {/* Message */}
      <div className="d-flex flex-column mb-20">
        <label htmlFor="message">Message * :</label>
        {errors.message && (
          <p className="form-error">{errors.message.message}</p>
        )}
        <textarea
          className=""
          placeholder="Votre message *"
          id="message"
          cols="20"
          rows="10"
          {...register("message")}
        ></textarea>
      </div>
      {/* checkBox */}
      <div className="d-flex flex-column mb-20 dataCheckbox">
        <label htmlFor="persoData" id="persoData">
          J'accepte que mes données personnelles saisies dans ce formulaire
          soient utilisées dans le cadre d'une prise de contact.*
        </label>

        <input {...register("persoData")} type="checkbox" />

        {errors.persoData && (
          <p className="form-error d-flex justify-center">
            {errors.persoData.message}
          </p>
        )}
      </div>

      <div className="d-flex justify-end">
        <button disabled={isSubmitting} className="btn btn-primary">
          Envoyer
        </button>
      </div>
    </form>
  );
};

ContactForm.propTypes = {
  setIsSend: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
};

export default ContactForm;
