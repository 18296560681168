import resavia from "../assets/images/portfolio/resavia.png";
import ohMyFood from "../assets/images/portfolio/ohMyFood.png";
import gameOn from "../assets/images/portfolio/gameOn.png";
import FISHEYE from "../assets/images/portfolio/FISHEYE.png";
import LesPetitsPlats from "../assets/images/portfolio/LesPetitsPlats.png";
import Billed from "../assets/images/portfolio/Billed.png";
import Kasa from "../assets/images/portfolio/Kasa.png";
import SportSee from "../assets/images/portfolio/SportSee.png";
import ArgentBank from "../assets/images/portfolio/ArgentBank.png";
import HRnet from "../assets/images/portfolio/HRnet.png";

// skills
import nodeJs from "../assets/images/skills/nodeJs.png";
import Javascript from "../assets/images/skills/Javascript.png";
import reactJs from "../assets/images/skills/reactJs.png";
import mongoAtlas from "../assets/images/skills/MongoDb_Atlas.png";
import jestJS from "../assets/images/skills/Jest.png";
import vsc from "../assets/images/skills/vsc.png";
import git from "../assets/images/skills/git.png";
import abodeXd from "../assets/images/skills/abodeXd.png";
import zoom from "../assets/images/skills/zoom.png";
import packOffice from "../assets/images/skills/packOffice.png";
import figma from "../assets/images/skills/figma.png";

// navBar
export const linksArray = [
  {
    label: "Accueil",
    to: `#welcome`,
  },
  {
    label: "Présentation",
    to: `#about`,
  },
  {
    label: "Mes compétences",
    to: `#skills`,
  },
  {
    label: "Projets",
    to: `#portfolio`,
  },
  {
    label: "Contact",
    to: `#contact`,
  },
];

// skills
export const experienceList = [
  {
    label: "Resavia",
    img: resavia,
    realized: "HTML . CSS",
    repository: "https://github.com/Stephan075/Reservia",
    link: "https://stephan075.github.io/Reservia/",
    to: "/",
  },
  {
    label: "OHMYFOOD",
    img: ohMyFood,
    realized: "HTML . CSS . SASS",
    repository: "https://github.com/Stephan075/FolioStephan_3_19082021",
    link: "https://stephan075.github.io/FolioStephan_3_19082021/",
    to: "/",
  },
  {
    label: "GAME ON",
    img: gameOn,
    realized: "HTML . CSS . JAVASCRIPT",
    repository: "https://github.com/Stephan075/FolioStephan_4_01092021",
    link: "https://stephan075.github.io/FolioStephan_4_01092021/",
    to: "/",
  },
  {
    label: "FISHEYE",
    img: FISHEYE,
    realized: "HTML . CSS . JAVASCRIPT . FETCH DB . ARIA",
    repository: "https://github.com/Stephan075/FolioStephan_6_23092021",
    link: "https://stephan075.github.io/FolioStephan_6_23092021/",
    to: "/",
  },
  {
    label: "Les Petits Plats",
    img: LesPetitsPlats,
    realized: "HTML . CSS . JAVASCRIPT . FETCH DB . FILTER . TRI",
    repository: "https://github.com/Stephan075/FolioStephan_7_06122021",
    link: "https://stephan075.github.io/FolioStephan_7_06122021/",
    to: "/",
  },
  {
    label: "Billed",
    img: Billed,
    realized: "KANBAN . JAVASCRIPT . JEST",
    repository: "https://github.com/Stephan075/Billed-app-FR-Front",
    to: "/",
  },
  {
    label: "Kasa",
    img: Kasa,
    realized: "REACT . JSX . ROUTER . FETCH DB . PROPTYPES ",
    repository: "https://github.com/Stephan075/FolioStephan_11_15032022",
    to: "/",
  },
  {
    label: "Sport See",
    img: SportSee,
    realized: "REACT . JSX . RECHARTS . FETCH DB . PROPTYPES . HOOKS ",
    repository: "https://github.com/Stephan075/FolioStephan_12_04042022",
    to: "/",
  },
  {
    label: "Argent Bank",
    img: ArgentBank,
    realized: "REACT . JSX . SWAGGER . FETCH API . @reduxjs/toolkit . HOOKS ",
    repository: "https://github.com/Stephan075/FolioStephan_13_15052021",
    to: "/",
  },
  {
    label: "HRnet",
    img: HRnet,
    realized:
      "REACT . JSX . NPM . STORAGE . @reduxjs/toolkit . MODULE . LIGHTHOUSE ",
    repository: "https://github.com/Stephan075/FolioStephan_14_10072021",
    link: "https://stephan075.github.io/FolioStephan_14_10072021",
    to: "/",
  },
];

export const skillsList = [
  {
    label: "Html / Css / Sass / Bootstrap",
    img: `https://infodocbib.net/wp-content/uploads/2012/08/HTML-CSS.jpg`,
  },
  {
    label: "Node js",
    img: nodeJs,
  },
  {
    label: "Javascript",
    img: Javascript,
  },
  {
    label: "React Js",
    img: reactJs,
  },
  {
    label: "MongoDb / Atlas",
    img: mongoAtlas,
  },
  {
    label: "Jest js",
    img: jestJS,
  },
  {
    label: "Visual studio code",
    img: vsc,
  },
  {
    label: "Git",
    img: git,
  },
  {
    label: "Abode XD",
    img: abodeXd,
  },
  {
    label: "Zoom",
    img: zoom,
  },
  {
    label: "Pack office",
    img: packOffice,
  },
  {
    label: "Figma",
    img: figma,
  },
];
