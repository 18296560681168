import styles from "./Hero.module.scss";
import React from "react";
import Social from "../Social/Social";
import { HashLink as Link } from "react-router-hash-link";

/**
 *
 * @returns {HTMLElement}
 */
const Hero = () => {
  return (
    <div className={styles.heroContainer}>
      <section id="welcome" className={`${styles.hero} text-uppercase`}>
        <div className={`${styles["hero--content"]} container`}>
          <h1>Stephan Folio</h1>
          <p>
            <span>Réactif et motivé</span>, je suis aujourd'hui à la recherche
            d'un emploi de <span>Développeur web JavaScript.</span>
            <br />
            <span> (disponible immédiatement 😎)</span>
          </p>
          <div className={`${styles["hero--separator"]}`}></div>
          {/* Social icons */}
          <Social />

          <Link
            to="#about"
            title="faire défiler vers le bas !"
            className="scroll-down"
            smooth
          />
        </div>
      </section>
    </div>
  );
};

export default Hero;
