import React, { useEffect, useState } from "react";
import styles from "./BackToTop.module.scss";

/**
 *
 * @returns {HTMLElement}
 */
const BackToTop = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {backToTopButton && (
        <div
          className={styles.backToTop}
          title="Back to Top"
          onClick={scrollUp}
        >
          <i className="fas fa-arrow-circle-up"></i>
        </div>
      )}
    </>
  );
};

export default BackToTop;
