import Social from "../../Social/Social";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Social />
      <p>Tout droits réservés © Stephan FOLIO | 2022</p>
    </footer>
  );
};

export default Footer;
