import styles from "./About.module.scss";
import downloadCv from "../../assets/pdf/CV_FOLIO_STEPHAN.pdf";
import photo from "../../assets/images/picture_transp_01.png";

/**
 *
 * @returns {HTMLElement}
 */
const About = () => {
  return (
    <section id="about" className={styles.about}>
      <div className="container">
        <h1 className="title text-uppercase">présentation</h1>
        <div className={styles["about--content"]}>
          <div className={styles["about--picture"]}>
            <img src={photo} alt="" />
          </div>
          <div className="">
            <h3 className="d-flex justify-center">
              Hello👋 , je suis Stephan Folio, 32 ans Développeur Web javascript
              React
            </h3>
            <p>
              Passionné par le web, je me suis formé aux métiers du web, depuis
              un peu plus de deux ans. D'abord en autodidacte, puis avec
              𝐎𝐩𝐞𝐧𝐂𝐥𝐚𝐬𝐬𝐫𝐨𝐨𝐦𝐬 (Développeur d'application - JavaScript React). Je
              suis sans cesse à la recherche de <span>nouveautés</span> et de{" "}
              <span>challenges</span>. Le métier de développeur web est donc
              apparu comme une évidence. Dans un monde où{" "}
              <span>l'informatique</span> est en plein boum et où l'on trouve
              une technologie qui ne cesse de se réinventer, j'y ai trouvé ma
              place et <span>je souhaite y contribuer</span>. Ma spécialisation
              se tourne vers le{" "}
              <span>javascript , HTML, CSS, React JS, nodeJs</span> la création
              d'applications Web de petite et moyenne taille, des
              fonctionnalités, des animations et le codage de mises en page
              interactives.
            </p>

            <p>
              De nature <span>curieux</span>, mes expériences professionnelles
              et personnelles m’ont permis de développer un certain nombre de
              soft-skills telles que{" "}
              <span>
                {" "}
                l’esprit d’équipe, la polyvalence ou encore la persévérance{" "}
              </span>{" "}
              .
            </p>

            <p>
              <span>Mes objectifs</span> : Je suis aujourd'hui à la recherche
              d'un emploi de développeur web javascript. Ce qui me motive est le
              désir ardent d’exprimer pleinement le potentiel que j’ai développé
              durant mes formations et mes années en tant qu’autodidacte, faire
              évoluer et développer mes compétences techniques dans une
              entreprise bienveillante.
            </p>
            <p>
              <span>Ce que je vais vous apporter</span> : Calme, autonomie,
              flexibilité sont des qualités de mon savoir-être qui profitera à
              votre entreprise et à l'équipe.
            </p>
          </div>
        </div>
        <div className="separator"></div>
        <div className={styles["about--contact"]}>
          <button className="btn btn-primary mr-10">
            <a href="#contact">
              <i className="fas fa-envelope" aria-hidden="true"></i>
              Entrons en contact !
            </a>
          </button>

          <button className="btn btn-primary">
            <a href={downloadCv} download="CV_Folio_Stephan">
              <i className="fas fa-download" aria-hidden="true"></i>
              Télécharger mon CV
            </a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default About;
