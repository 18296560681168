import React from "react";
import { experienceList } from "../../_mocks_/data";
import styles from "./Portfolio.module.scss";

/**
 *
 * @returns {HTMLElement}
 */
const Portfolio = () => {
  return (
    <section id="portfolio" className={styles.portfolio}>
      <div className="container">
        <h1 className="title text-uppercase">Mes dernières réalisations</h1>

        <div className={`${styles.experiences}`}>
          <ul>
            {experienceList.map((experience) => (
              <li key={experience.label}>
                <div>
                  <div>
                    <img src={experience.img} alt="" />
                  </div>
                  <div className={`${styles.experiences__elemText}`}>
                    <p>{experience.label}</p>
                  </div>
                </div>
                <div className={`${styles.infos}`}>
                  <div className={`${styles.infos__tech}`}>
                    <span> Site réalisé en : </span>
                    <p>{experience.realized}</p>
                  </div>
                  <div className={`${styles.infos__links}`}>
                    <div
                      className={`${styles["infos__links--detail"]}`}
                      title="Plus d'info"
                    >
                      <i className="fa-solid fa-magnifying-glass-plus"></i>
                    </div>
                    {experience.link && (
                      <div
                        className={`${styles["infos__links--seeSite"]}`}
                        title="Voir le site"
                      >
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={experience.link}
                        >
                          <i className="fa-solid fa-eye"></i>
                        </a>
                      </div>
                    )}
                    <div
                      className={`${styles["infos__links--seeRepository"]}`}
                      title="Voir le Repository"
                    >
                      <a
                        rel="noreferrer"
                        target="_blank"
                        title="Voir le Repository"
                        href={experience.repository}
                      >
                        <i className="fab fa-github" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
