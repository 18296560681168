import styles from "./App.module.scss";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Homepage/Home";

function App() {
  return (
    <div className={`d-flex flex-column ${styles.appContainer}`}>
      <Layout>
        <Home />
      </Layout>
    </div>
  );
}

export default App;
