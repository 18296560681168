import Footer from "./Footer/Footer";
import Header from "./Header/Header";

function Layout({ children }) {
  return (
    <main className="flex-fill d-flex flex-column">
      <Header />
      {children}
      <Footer />
    </main>
  );
}

export default Layout;
