import BackToTop from "../../components/BackToTop/BackToTop";
import About from "../../pages/About/About";
import Contact from "../Contact/Contact";
import Portfolio from "../Portfolio/Portfolio";
import Skills from "../Skills/Skills";
import Welcome from "../Welcome/Welcome";

const Home = () => {
  return (
    <div className="flex-fill">
      <BackToTop />
      <Welcome />
      <About />
      <Skills />
      <Portfolio />
      <Contact />
    </div>
  );
};

export default Home;
